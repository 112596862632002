import { Component, HostListener, inject, OnInit, OnDestroy, signal, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { JobsService } from '../jobs/jobs.service';
import { ISqlJob } from '../jobs/sql-job.interface';
import { Confirmation, ConfirmationService, MessageService } from 'primeng/api';
import { Observable, Subject, firstValueFrom } from 'rxjs';
import { Loading } from 'notiflix';
import { AuthService } from '../auth/auth.service';
import { NotesService } from '../notes/notes.service';
import { IFormState } from '../forms/inspection-form/inspection-form.component';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InspectionFormComponent } from '../forms/inspection-form/inspection-form.component';

@Component({
  selector: 'app-inspection',
  templateUrl: './inspection.component.html',
  styleUrls: ['./inspection.component.scss']
})
export class InspectionComponent implements OnInit {
  canNavigateAwaySubject: Subject<boolean> = new Subject<boolean>();

  scJob: ISqlJob = {} as ISqlJob;
  isLoading = true;
  hasUnsavedPhotoChanges = false;
  hasUnsavedFormChanges = false;
  isInspectionImmutable = false;
  formState: IFormState = {} as IFormState;
  formStateEvent: any;
  immutableInspectionStatusIds = [1, 3, 6, 7, 8, 9];
  isLinkValid = false;
  hasPhotos = signal<boolean>(false);
  hasRequiredPhotos = signal<boolean>(true);
  isFormClosed = signal<boolean>(false);
  isSaveFinal = signal<boolean>(false);
  pageTitle = 'Invalid SiteCheck Inspection Form Link';

  @HostListener('window:beforeunload', ['$event'])
  public onPageUnload($event: BeforeUnloadEvent): void {
    if (this.hasUnsavedChanges()) {
      $event.returnValue = true;
    }
  }

  private messageService = inject(MessageService);
  private notesService = inject(NotesService);

  @ViewChild('inspectionFormComponent') inspectionFormComponent!: InspectionFormComponent;

  constructor(private activatedRoute: ActivatedRoute,
              private confirmationService: ConfirmationService,
              // private router: Router,
              private jobsService: JobsService,
              private authService: AuthService,
              public ref: DynamicDialogRef,
              public config: DynamicDialogConfig
              ) { }

  ngOnInit(): void {
    if(this.config?.data?.job?.scJobId) {
      this.scJob = this.config.data.job;
      this.pageTitle = '';
      this.isInspectionImmutable = false;
      this.isLinkValid = true;
      this.isLoading = false;
    } else {
      const jobId = this.activatedRoute.snapshot.paramMap.get('jobId') || '';
      const token = this.activatedRoute.snapshot.paramMap.get('token') || '';
      this.jobsService.getScJobByJobId(jobId)
        .then(async scJob => {
          if (scJob) {
            // @ts-ignore
            this.scJob = scJob;
            this.pageTitle = `CSI/SiteCheck Inspection Submission for Job # ${scJob.scJobId}`;
            this.isInspectionImmutable = this.immutableInspectionStatusIds.includes(this.scJob.scJobStatusId);
            this.isLinkValid = true;
            await this.authService.login('sitecheck-guest2', '1234+Five');
          }
          this.isLoading = false;
        });
    }
  }

  hasUnsavedChanges(): boolean {
    return this.hasUnsavedFormChanges || this.hasUnsavedPhotoChanges;
  }

  closeDialog() {
    this.confirmationService.confirm({
      key: 'inspectionConfirmDialog',
      message: `Any unsaved changes will be lost. Are you sure you want to close the inspection form?`,
      accept: () => {
        this.isFormClosed.set(true);
        this.ref.close();
      }
    });
  }

  async qcSubmit() {
      this.confirmationService.confirm({
        key: 'inspectionConfirmDialog',
        header: 'Confirm QC Submission',
        message: `You will not be able to make any further changes. Are you sure you want to submit this job for QC?`,  
        reject: () => {
        },
        accept: async () => {
          const formComponent = this.inspectionFormComponent.getFormioInstance();
          await this.jobsService.updateJob( this.config.data.job.jobId, { inspectionData: formComponent.submission.data })
          const inspection = await this.jobsService.validateJobForQc(this.config.data.job.jobId, {});
          if (!inspection.isReadyForQc) {
            const isValid = formComponent.formio.checkValidity(null, true);
            if (!isValid) {
              formComponent.formio.submit(); // highlight any errors
            }
            this.messageService.add({
              severity: 'error',
              summary: 'QC Submission Failed',
              detail: 'Please ensure all required fields are filled out correctly and you have uploaded your photos (if required for this inspection).'
            });
            return;
          }
          Loading.standard('Saving and Submitting for QC Review...');
          this.jobsService.updateJob(this.config.data.job.jobId, { status: 'QC', inspectionData: formComponent.submission.data })
            .then(res => {
              return this.jobsService.postFormDataLegacy(this.config.data.job.jobId);
            })
            .then(res => {
              this.messageService.add({
                severity: 'success',
                summary: 'Saved as Final Submit...',
                detail: 'The form has been submitted successfully.'
              });
              this.notesService.createScJobNote(this.config.data.job.scJobId,
                `Inspector has completed the data entry process and submitted the finalized report. The status was updated to QC. `, true)
                  .catch(e => console.error(`Unable to save note on final submit and save.\n${e}`));
                this.isSaveFinal.set(true);
                Loading.remove();
                this.ref.close();
            })
            .catch (e => {
              console.log(`Unable to save and submit final.\n${e}`);
              Loading.remove();
              this.messageService.add({
                severity: 'error', summary: 'Unable Save & Submit as Final...',
                detail: 'The form was not submitted. Please try again or contact your coordinator for help.'
              });
            })
            .finally( () => {
              Loading.remove();
            });
        }
      });
  }

  async saveForm() {
    try {
      const formComponent = this.inspectionFormComponent.getFormioInstance();
      await this.jobsService.updateJob( this.config.data.job.jobId, { inspectionData: formComponent.submission.data })
      this.messageService.add({
        severity: 'success', summary: 'Inspection Saved',
        detail: 'Changes made to the inspection form have been successfully saved.'
      });     
    } catch (error) {
      this.messageService.add({
        severity: 'error', summary: 'Error Saving Inspection',
        detail: 'An error occurred while saving the inspection. Please try again or contact your coordinator for help.'
      });
    }
    return;
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    if (this.hasUnsavedChanges()) {
      let work = `work`;
      if (this.hasUnsavedFormChanges && this.hasUnsavedPhotoChanges) {
        work = 'photos and inspection form changes';
      } else if (this.hasUnsavedFormChanges && !this.hasUnsavedPhotoChanges) {
        work = `inspection form changes`;
      } else if (this.hasUnsavedPhotoChanges && !this.hasUnsavedFormChanges) {
        work = `photo changes`;
      }
      this.confirmationService.confirm({
        key: 'inspectionConfirmDialog',
        message: `It appears you have unsaved ${work}, are you sure you want to leave this screen?`,
        defaultFocus: 'reject',
        closeOnEscape: true,
        accept: () => {
          this.canNavigateAwaySubject.next(true);
        },
        reject: () => {
          this.canNavigateAwaySubject.next(false);
        }
      });
      return this.canNavigateAwaySubject;
    }
    return true;
  }

  formStateChange(event: any) {
    this.formStateEvent = event;
  }
}
