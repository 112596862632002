import { Injectable, Injector, signal, computed } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpBackend } from '@angular/common/http';
import { Router } from '@angular/router';
import { IUserAuth } from './user-auth';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { environment } from'../../environments/environment';
import { firstValueFrom } from 'rxjs';
import { AuthCommunicationService } from './auth.communication.service';
import { Report } from 'notiflix';

interface IUserAuthPayload {
  username: string;
  sub: string; 
  roles: number[];
  userProfileId: number; 
}
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly USER_AUTH_TOKEN_KEY = 'userAuth';
  private env = environment;
  private tokenCheckInterval: any; 
  
  private _userAuthSignal = signal<IUserAuth | null>(null);
  public readonly userAuth$ = computed(() => this._userAuthSignal());

  private http: HttpClient;

  constructor(
    private injector: Injector, 
    private httpBackend: HttpBackend,
    private authCommunicationService: AuthCommunicationService) {
    this.http = new HttpClient(this.httpBackend);
  
    const accessToken = this.getLocalStorageAccessToken();
    if (accessToken && this.isTokenValid(accessToken)) {
      this.processAccessTokenFromStorage();
    }
  
    // listener for cross-tab synchronization
    window.addEventListener('storage', (event) => {
      if (event.key === this.USER_AUTH_TOKEN_KEY) {
        if (event.newValue) {
          this.processAccessTokenFromStorage();//on login
        } else {
          this.emitAndRedirectToLogin();        
        }
      }
    });
    this.startTokenCheck();
  }

  //Probably will be replaced for the new refrsh token logic
  //for now it is the only way to check if the token is still valid
  //the errror 401 trigger the logout, but when the user is not sending any request
  //the logout is not triggered
  
  private startTokenCheck() {
    this.tokenCheckInterval = setInterval(() => {
      const accessToken = this.getLocalStorageAccessToken();
      if (!accessToken || !this.isTokenValid(accessToken)) {
        this.emitAndRedirectToLogin();
      }
    }, 60000); 
  }

  private stopTokenCheck() {
    if (this.tokenCheckInterval) {
      clearInterval(this.tokenCheckInterval);
    }
  }

  //----------------------------------------------------

  private emitAndRedirectToLogin(isManualLogout = false) {
    this._userAuthSignal.set(null); 
    this.authCommunicationService.emitLogout();
    
    //if the logout is manual, we don't want to show the session expired message 
    //on the tab that was manually logged out
    if(!isManualLogout) {
    Report.info(
      'System Info',
      'Your session has expired - Please login again',
      'Ok',
      );
    }

    const router = this.injector.get(Router);
    router.navigate(['/login']);
  }

  private async processAccessTokenFromStorage(): Promise<void> {
    const accessToken = this.getLocalStorageAccessToken();
    if (accessToken && this.isTokenValid(accessToken)) {
      const userInfo = await this.getUserInfo(accessToken);
      this._userAuthSignal.set({
        accessToken,
        userName: `${userInfo?.FirstName || 'Unknown'} ${userInfo?.LastName || 'User'}`,
        roles: userInfo.roles,
        userProfileId: userInfo.userProfileId,
      });
    }
  }
  
  async userAuth(): Promise<IUserAuth|null> {
    if (this._userAuthSignal() === null) {
      await this.processAccessTokenFromStorage();
    }
    return this._userAuthSignal();
  }
  
  get token(): string {
    return this.getLocalStorageAccessToken();
  }
  
  getLocalStorageAccessToken(): string {
    try {
      const userAuth = localStorage.getItem(this.USER_AUTH_TOKEN_KEY);
      return userAuth ? JSON.parse(userAuth).accessToken : '';
    } catch (error) {
      console.error('Failed to retrieve access token from local storage:', error);
      return '';
    }
  }

  async login(loginName: string, password: string): Promise<any|void> {
    try {
      const user: any = await firstValueFrom( this.http
        .post(
          `${this.env.baseApiUrl}/auth/login`,
          {
            loginName,
            password,
          }
        ));

        if (user?.accessToken) {
          const userInfo = await this.getUserInfo(user.accessToken);
          const userAuth = {
            accessToken: user.accessToken,
            userName: `${userInfo?.FirstName || 'Unknown'} ${userInfo?.LastName || 'User'}`,
            roles: userInfo.roles,
            userProfileId: userInfo.userProfileId,
          } as IUserAuth;
          localStorage.setItem(this.USER_AUTH_TOKEN_KEY, JSON.stringify({accessToken: userAuth.accessToken}));
          this._userAuthSignal.set(userAuth);
          return this._userAuthSignal();
        }
    } catch (error) {
      console.error('Failed to login:', error);
      throw error;
    }
  }

  isTokenValid(accessToken: string): boolean {
    try {
      if (!accessToken) {
        return false;
      }

      const decoded = jwtDecode<JwtPayload>(accessToken);
      if (!decoded?.exp) {
        return false;
      }
  
      const date = new Date(0); 
      date.setUTCSeconds(decoded.exp);
      if (date.valueOf() > new Date().valueOf()) {
        return true;
      } else {
        this.logout();
        return false;
      }
    } catch (error) {
      console.error('Failed to validate token:', error);
      return false;
    }
  }

  hasValidToken(): boolean {
    const token = this.getLocalStorageAccessToken();
    return !!token && this.isTokenValid(token);
  }

  logout(isManualLogout = false) {
    this.stopTokenCheck();
    localStorage.removeItem(this.USER_AUTH_TOKEN_KEY);
    this.emitAndRedirectToLogin(isManualLogout);
  }

  decodeUserAuthToken(token: string): IUserAuthPayload|null {
    return jwtDecode<JwtPayload>(token) as IUserAuthPayload|null;
  }

  async getUserInfo(accessToken?: string): Promise<any|void> {
    accessToken = accessToken || this.getLocalStorageAccessToken();
    if (!accessToken) {
      return;
    }
  
    const userAuthPayload = this.decodeUserAuthToken(accessToken) as IUserAuthPayload;
  
    if (userAuthPayload.userProfileId && this.isTokenValid(accessToken)) {
      try {
        const userProfile = await firstValueFrom(
          this.http.get(`${this.env.baseApiUrl}/users/profile/${userAuthPayload.userProfileId}`, {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          })
        );
  
        if (userProfile) {
          return {
            ...userAuthPayload,
            ...userProfile
          };
        } else {
          return userAuthPayload;
        }
      } catch (error) {
        console.error('Failed to fetch user profile:', error);
        return userAuthPayload;
      }
    }
    return userAuthPayload;
  }

  passwordReset(token: string, newPassword: string) {
    const http = this.injector.get(HttpClient);
    return http.post(`${this.env.baseApiUrl}/auth/password-reset`, { token , newPassword });
  }

}
