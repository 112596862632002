import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Dimensions, ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-editor',
  templateUrl: './photo-editor.component.html',
  styleUrls: ['./photo-editor.component.scss']
})
export class PhotoEditorComponent implements OnInit {
  file: any;
  showCropper = false;
  transform: ImageTransform = {
    scale: 1,
    rotate: 0,
    flipH: false,
    flipV: false,
  };
  croppedEvent: any;
  croppedImage: any;
  canvasRotation = 0;
  sourceImageDimensions: any;
  scale: number;
  rotation = 0;

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig, private sanitizer: DomSanitizer,
  ) {
    this.scale = 1;

  }

  ngOnInit(): void {
    if (this.config.data.file) {
      this.file = this.config.data.file;
      const img = new Image();
      img.src = URL.createObjectURL(this.file);
      img.onload = () => {
        this.sourceImageDimensions = { 
          width: img.naturalWidth, 
          height: img.naturalHeight 
        };
        URL.revokeObjectURL(img.src);
      };
    }
  }

  imageLoaded(): void {
    this.showCropper = true;
  }

  onImageCropped(event: ImageCroppedEvent): void {
    if (event.blob) {
      this.croppedImage = event.blob;
    }
  }

  private async compressImage(blob: Blob): Promise<Blob> {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = URL.createObjectURL(blob);
      
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = img.width;
        canvas.height = img.height;
        
        ctx?.drawImage(img, 0, 0);
        
        canvas.toBlob((resultBlob) => {
          URL.revokeObjectURL(img.src);
          resolve(resultBlob as Blob);
        }, 'image/jpeg', 0.4);
      };
    });
  }

  cropperReady(sourceImageDimensions: Dimensions): void {
    this.sourceImageDimensions = sourceImageDimensions;
  }

  resetImage(): void {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
  }

  zoomOut(): void {
    this.scale -= .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  zoomIn(): void {
    this.scale += .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  rotate(direction: string): void {
    if (direction === 'left') {
      this.canvasRotation--;
    } else {
      this.canvasRotation++;
    }
    this.flipAfterRotate();
  }

  private flipAfterRotate(): void {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }

  async save(): Promise<void> {
    try {
      const compressedBlob = await this.compressImage(this.croppedImage);
      const file: any = new File([compressedBlob], this.file.name, { type: this.file.type });
      this.ref.close(file);
    } catch (error) {
      console.error(error);
    }
  }

  cancel(): void {
    this.ref.close();
  }

  loadImageFailed(): void {
    console.log('Unable to load image into the crop/resize editor.');
  }
}
