<div class="p-grid h-full w-full">
        <div class="p-col h-full w-full relative">
                <image-cropper [imageFile]="file" [imageChangedEvent]="croppedEvent"
                        [maintainAspectRatio]="false" [containWithinAspectRatio]="true" [transform]="transform"
                        [autoCrop]="true" [canvasRotation]="canvasRotation" (imageCropped)="onImageCropped($event)"
                        (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady(sourceImageDimensions)"
                        (loadImageFailed)="loadImageFailed()" [style.display]="showCropper ? null : 'none'"
                        [onlyScaleDown]="true"
                        [resizeToWidth]="sourceImageDimensions?.width"
                        [resizeToHeight]="sourceImageDimensions?.height"
                        class="h-[calc(100%-35.1px)] w-full">
                </image-cropper>
                <div class="editor-controls absolute bottom-0 left-0 right-0 mb-2">
                        <div class="flex flex-row justify-content-center align-items-center gap-2">
                                <!--      <button pButton pRipple type="button" icon="pi pi-window-minimize" (click)="crop()"-->
                                <!--              class="p-button-rounded p-button-text p-button-info"-->
                                <!--              pTooltip="Crop" tooltipPosition="top"-->
                                <!--              showDelay="500" hideDelay="150"></button>-->
                                <p-button pRipple icon="pi pi-replay" (click)="rotate('left')" [rounded]="true"
                                        [outlined]="true" severity="info" pTooltip="Rotate Left"
                                        tooltipPosition="top"></p-button>
                                <p-button pRipple icon="pi pi-refresh" (click)="rotate('right')" [rounded]="true"
                                        [outlined]="true" severity="info" pTooltip="Rotate Right"
                                        tooltipPosition="top"></p-button>
                                <p-button pRipple icon="pi pi-plus" (click)="zoomIn()" [rounded]="true"
                                        [outlined]="true" severity="info" pTooltip="Zoom In"
                                        tooltipPosition="top"></p-button>
                                <p-button pRipple icon="pi pi-minus" (click)="zoomOut()" [rounded]="true"
                                        [outlined]="true" severity="info" pTooltip="Zoom Out"
                                        tooltipPosition="top"></p-button>
                                <p-button pRipple type="button" icon="pi pi-check" (click)="save()" [rounded]="true"
                                        [outlined]="true" severity="success">
                                </p-button>
                                <p-button pRipple type="button" icon="pi pi-times" (click)="cancel()" [rounded]="true"
                                        [outlined]="true" severity="danger">
                                </p-button>

                        </div>
                </div>
        </div>
</div>