import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpRequest} from '@angular/common/http';
import { IFile } from './interfaces/upload.models';
import {firstValueFrom, Observable} from 'rxjs';
import {PhotoLabel} from '../settings/photo-labels/classes/photo-label.class';
import {SettingsService} from '../settings/settings.service';
import { environment } from'../../environments/environment';
import { ISignedUrl } from './interfaces/upload.models';
import { IPhoto } from './interfaces/upload.models';

@Injectable({
  providedIn: 'root'
})
export class PhotosService {
  env = environment;

  constructor(
    private http: HttpClient,
    private settingsService: SettingsService
  ) {}

  getSignedUrls(files: IFile[]): Promise<ISignedUrl[]> {
    return firstValueFrom( this.http.post<ISignedUrl[]>(`${this.env.baseApiUrl}/aws/sign-for-put/`, files) );
  }

  uploadPhotoS3(file: any, url: string): Observable<any|undefined> {
    try {
      const headers = new HttpHeaders({
        'Content-Type': file.type,
      });
      const uploadReq = new HttpRequest(
        'PUT',
        url,
        file,
        {
          headers,
          reportProgress: true,
        }
      );
      return this.http.request(uploadReq); 
    } catch (error) {
      console.log(error);
      return new Observable(observer => {
        observer.error(error);
      });
    }
  }

  async savePhotos(jobId: string, photos: IPhoto[]): Promise<any> {
    const job = await firstValueFrom(
      this.http.put(`${this.env.baseApiUrl}/jobs/${jobId}`, { inspectionPhotos: photos })
    );
    return job;
  }

  async getJob(jobId: string): Promise<any> {
    const job = await firstValueFrom( this.http.get(`${this.env.baseApiUrl}/jobs/${jobId}`) );
    return job;
  }

  async getS3Photo(url: string): Promise<Blob|void> {
    try {
      const photoBuffer = await firstValueFrom( this.http.get(url, { responseType: 'blob'}) );
      return photoBuffer;
    } catch (error) {
      console.log(error);
    }
    return;
  }
    
  async deleteS3Photo(bucket: string, key: string): Promise<any> {
    return await firstValueFrom(
      this.http.post(`${this.env.baseApiUrl}/aws/delete-s3-object`, { bucket, key })
    );
  }

  async getS3PhotoBucket(): Promise<string> {
    const response = await firstValueFrom(
      this.http.get<{bucket: string}>(`${this.env.baseApiUrl}/aws/get-s3-bucket-name`)
    );
    return response.bucket;
  }

  async getPhotoLabels(): Promise<PhotoLabel[]> {
    const photoLabels = await this.settingsService.getPhotoLabels();
    return photoLabels;
  }

  async validateUloadedPhotos(photos: any): Promise<IPhoto[]|void> {
    const photoObjects = photos.map((photo: IPhoto) => {
      return {
        key: photo.key,
        bucket: photo.bucket,
        type: photo.type,
        size: photo.size,
        isValid: false,
      };
    });
    const validatedPhotos = await firstValueFrom( this.http.post(`${this.env.baseApiUrl}/photos/validate`, photoObjects) ) as IPhoto[];
    validatedPhotos.forEach((photo, index) => {
      photos[index].isValid = photo.isValid;
    });
    return validatedPhotos;
  }
}
