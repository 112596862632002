import { Component, OnInit, ViewEncapsulation, ChangeDetectorRef, Inject, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcrumbService } from '../../app.breadcrumb.service';
import { JobsService } from '../jobs.service';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { UploadDialogComponent } from '../../photos/upload-dialog/upload-dialog.component';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { Loading } from 'notiflix';
import { ISqlJob} from '../sql-job.interface';
import { Clipboard } from '@angular/cdk/clipboard';
import { InspectionFormDialogComponent } from '../../forms/inspection-form-dialog/inspection-form-dialog.component';
import { AppService } from '../../app.service';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ToastModule } from 'primeng/toast';
import { TableModule } from 'primeng/table';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TooltipModule } from 'primeng/tooltip';
import { InputTextModule } from 'primeng/inputtext';
import { DatePipe } from '@angular/common';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule } from '@angular/forms';
import { UsersService } from '../../users/users.service';
import { UserRole } from '../../users/roles.enum';
import { TagModule } from 'primeng/tag';
import { NotesDialogComponent } from '../../notes/notes-dialog/notes-dalog.component';
import { DateTime } from 'luxon';
import { AuthService } from '../../auth/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { firstValueFrom, Subscription } from 'rxjs';
import { Overlay } from 'primeng/overlay';
import { JOB_STATUS } from '../job-status.enum';
import { BadgeModule } from 'primeng/badge';
import { NotesService } from '../../notes/notes.service';
import { DropdownModule } from 'primeng/dropdown';
import { InputGroupModule } from 'primeng/inputgroup';
import { PhoneNumberPipe } from '../../pipes/phone-number.pipe';
import { InspectionComponent } from '../../inspection/inspection.component';
import { environment } from '../../../environments/environment';
import { PdfFormComponent } from '../../pdf-form/pdf-form.component'
import { AuthCommunicationService } from 'src/app/auth/auth.communication.service';
type ButtonVariant = "success" | "secondary" | "info" | "warning" | "danger" | "contrast" | undefined;
@Component({
  standalone: true,
  selector: 'app-jobs-manager',
  imports: [
    ScrollPanelModule,
    ToastModule,
    TableModule,
    ConfirmDialogModule,
    CommonModule,
    TooltipModule,
    InputTextModule,
    ClipboardModule,
    CalendarModule,
    OverlayPanelModule,
    FormsModule,
    TagModule,
    BadgeModule,
    DropdownModule,
    InputGroupModule,
    PhoneNumberPipe,
  ],
  providers: [DialogService, ConfirmationService],
  templateUrl: './jobs-manager.component.html',
  styleUrls: ['./jobs-manager.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class JobsManagerComponent implements OnInit, OnDestroy {
  jobs: ISqlJob[] = [];
  currentUser: any;
  currentUserRoleName: string = '';
  currentUserPrimaryRole: UserRole = 0;
  datePipe: DatePipe = new DatePipe('en-US');
  scheduledDate: Date | null = null;
  UserRole = UserRole;
  statusTotals: { [status: string]: number } = {};
  dateTime = DateTime;
  JOB_STATUS = JOB_STATUS;
  selectedJobStatusId: number = 0;
  isShowDelivered: boolean = false;
  env = environment;
  ref: DynamicDialogRef | undefined; 
  private dialogRefs: DynamicDialogRef[] = []; // keep track of all dialog refs

  calculateDueDate = this.jobsService.calculateDueDate;

  private logoutSubscription!: Subscription;

  constructor(
    private breadcrumbService: BreadcrumbService,
    private jobsService: JobsService,
    private confirmationService: ConfirmationService,
    private dialogService: DialogService,
    private messageService: MessageService,
    private clipboard: Clipboard,
    private app: AppService,
    private users: UsersService,
    private authService: AuthService,
    private http: HttpClient,
    private cd: ChangeDetectorRef,
    private notesService: NotesService,
    private authCommunicationService: AuthCommunicationService
  ) {
      this.breadcrumbService.setItems([
        {label: 'Jobs Manager', routerLink: ['/']}
      ]);
    }

  ngOnInit(): void {
    this.logoutSubscription = this.authCommunicationService.logout$.subscribe(() => {
      this.closeAllModals();
    });
    this.getJobs();
  }

  ngOnDestroy() {
    if (this.logoutSubscription) {
      this.logoutSubscription.unsubscribe();
    }
    this.closeAllModals();
  }

  closeAllModals() {
    this.dialogRefs.forEach(ref => ref.close());
    this.dialogRefs = [];
  }

  sendLinkEmail(job: ISqlJob, event: any): void {
    if (!event.shiftKey) {
      this.confirmationService.confirm({
        key: 'jobsManagerConfirmDialog',
        message: `Are you sure you want to send the link for Job# ${job.scJobId}?`,
        accept: () => {
          console.log('Sending email to assigned rep...');
          this.jobsService.sendLinkEmail(job.jobId, job.scJobId)
            .then(res => {
              job.trackingStatus = res.status;
              this.messageService.add({severity: 'success', summary: 'Email sent to inspector', detail: job.scRepEmail});
            });
        }
      });
    } else {
      this.jobsService.getInspectionFormLink(job.jobId, job.scJobId)
        .then(data => {
          if (data) {
            if ( this.clipboard.copy(data.link.toString()) ) {
              this.messageService.add({
                severity: 'success',
                summary: 'Link copied',
                detail: 'You can now paste the link anywhere you like.'
              });
            } else {
              this.messageService.add({
                severity: 'warning',
                summary: 'Link Logged to Console',
                detail: 'Check the developer console for the link.'
              });
            }
            console.log(data.link);
          }
        });
    }

  }

  uploadInspection(job: ISqlJob): void {
    this.confirmationService.confirm({
      key: 'jobsManagerConfirmDialog',
      message: `Are you sure you want to upload job# ${job.scJobId} to the legacy server?`,
      accept: async () => {
        Loading.standard('Transferring Photos...');
        this.jobsService.transferJobToLegacy(job.jobId)
          .then(res => {
            Loading.remove();
            if (res.data.sql) {
              this.messageService.add({
                severity: 'success',
                summary: 'Job Photos Transferred',
                detail: 'You can now finish processing the job in the legacy system.'
              });
            } else {
              this.messageService.add({
                severity: 'info',
                summary: 'Nothing Transferred',
                detail: 'There were no photos found for upload to the legacy system.'
              });
            }
          })
          .catch(err => {
            console.log(`Unable to upload inspection data to legacy system.\n${err}`);
            Loading.remove();
            this.messageService.add({
              severity: 'error', summary: 'Unable to Upload...',
              detail: 'Unable to upload inspection data to legacy system.'
            });
          })
          .finally(() => {
            Loading.remove();
          });
      }
    });
  }

  openInspectionForm(job: ISqlJob, event: any): void {
    if (!event?.shiftKey) {
      const ref = this.dialogService.open(InspectionFormDialogComponent, {
        modal: true,
        data: {
          job
        },
        header: `Inspection Form for Job# ${job.scJobId}`,
        width: '90vw',
        height: '90vh',
        showHeader: true,
        focusOnShow: true,
        closeOnEscape: false,
        closable: true,
        // styleClass: 'inspection-form-dialog',
        contentStyle: { 'height': '100%', 'overflow-y': 'hidden !important' },
      });
  
      ref.onClose.subscribe(() => {
        this.getJob(job.jobId);
      });
      
      this.dialogRefs.push(ref); 
    } else {
      
    }
  }

  openInspection(job: ISqlJob, event: any): void {
    if (!event?.shiftKey) {
      const ref = this.dialogService.open(InspectionComponent, {
        data: {
          job
        },
        header: `Inspection Photos / Form for Job# ${job.scJobId}`,
        width: '90vw',
        height: '90vh',
        showHeader: true,
        focusOnShow: true,
        closeOnEscape: false,
        contentStyle: { 'height': '100%', 'overflow-y': 'hidden !important' },
        closable: true,
      });
  
      ref.onClose.subscribe(() => {
        this.getJob(job.jobId);
      });

      this.dialogRefs.push(ref); 
    } else {
      
    }
  }


  openPhotoUpload(job: ISqlJob, event: any): void {
    if (!event?.shiftKey) {
      const ref = this.dialogService.open(UploadDialogComponent, {
        data: {
          job
        },
        header: `Upload Photos for Job# ${job.scJobId}`,
        width: '85vw',
        height: '85vh',
        showHeader: true,
        
        closeOnEscape: false,
      });
  
      ref.onClose.subscribe(() => {
        // if (modifiedForm) {
        //   console.log(modifiedForm);
        //   form = modifiedForm;
        //   this.messageService.add({severity: 'info', summary: 'Inspection form saved', detail: modifiedForm.name});
        // }
      });

      this.dialogRefs.push(ref);
    } else {

    }
  }

  openNotesDialog(job: ISqlJob): void {
    const ref = this.dialogService.open(NotesDialogComponent, {
      data: {
        job,
        currentUser: this.currentUser,
      },
      header: `Job ${job.scJobId} / Notes for ${job.scInsuredName.toUpperCase()}`,
      width: '80vw',
      height: '80vh',
      showHeader: true,
      closeOnEscape: false,
    });

    ref.onClose.subscribe(() => {

    });
    
    this.dialogRefs.push(ref);
  }

  openCalendar(job: ISqlJob): void {
    // const ref = this.dialogService.open(UploadDialogComponent, {
    //   data: {
    //     job
    //   },
    //   header: `Schedule Inspection for Job# ${job.scJobId}`,
    //   width: '85%',
    //   showHeader: true,
    //   closeOnEscape: false,
    // });

    // ref.onClose.subscribe(() => {

    // });
  }

  getDueDateStyle(requestDate: string, userRole: UserRole): ButtonVariant {
    const dueDate = DateTime.fromISO(requestDate).plus({ days: userRole === UserRole.REP ? 14 : 30 });
    const now = DateTime.local();
    const diff = dueDate.diff(now, 'days').days;
    if (diff <= 0) {
      return 'danger';
    } else if (diff <= 5) {
      return 'warning';
    } else {
      return 'success';
    }
  }

  getDistinctStatusesAndTotals(jobs: any): { [status: string]: number } {
    const statusTotals: { [status: string]: number } = {};
    for (const job of jobs) {
      const status = job.scJobStatus;
      if (status in statusTotals) {
        statusTotals[status]++;
      } else {
        statusTotals[status] = 1;
      }
    }
    return statusTotals;
  }

  async openPdf(job: ISqlJob, event: any): Promise<void> {
    Loading.dots('Rendering PDF Form...');
    let blob: Blob | null;

    const pdfRenderUrl = `${this.env.baseApiUrl}/jobs/render-pdf/${job.scJobId}${!(event?.shiftKey) ? '?flatten' : ''}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/pdf',
      'Authorization': `Bearer ${this.authService.token}`
    });
    try {
      blob = await firstValueFrom( this.http.get(pdfRenderUrl, { 
        headers: headers,
        responseType: 'blob', 
      }));
      if (!blob) {
        throw new Error('Unable to open the PDF form.');
      }
      const url = window.URL.createObjectURL(blob);
      blob = null;
      if (event?.shiftKey) {
        this.ref = this.dialogService.open(PdfFormComponent, { 
          data: { url: url},
          header: 'PDF Form',
          width: '90vw',
          height: '90vh',
          // closable: true,
          // closeOnEscape: true,
          modal: true,
          style: { 'height': '100%', 'overflow-y': 'hidden !important' },
        });
    
        this.ref.onClose.subscribe((response) => {
    
        });
        
        this.dialogRefs.push(this.ref);
        this.ref = undefined;
        
        return;
      }
      window.open(url, '_blank');
    } catch(err: any) {
        console.log(err);
        this.messageService.add({
          severity: 'error',
          summary: 'Unable to Open the PDF Form',
          detail: err.message ? err.message : 'An unknown error occurred.'
        });
    } finally {
      blob = null;
      Loading.remove();
    }
  }

  async updateScheduledDate(job: ISqlJob, overlaypanel: any): Promise<void> {
    if (!this.scheduledDate) {
      return;
    }
    job.scScheduledDate = this.scheduledDate.toISOString();
    const resp = await this.jobsService.updateScheduledDate(job.scJobId, job.scScheduledDate);
    console.log(job.scScheduledDate);
    this.scheduledDate = null;
    overlaypanel.toggle(false);
    this.getJob(job.jobId);
  }

  showDatePanel(event: Event, job: ISqlJob): void {
    this.scheduledDate = new Date(job.scScheduledDate);
    console.log(this.scheduledDate);
  }

  minimumScheduledDate(): Date {
    const minDate =  this.dateTime.local().toJSDate();
    console.log(minDate);
    return minDate;
  }

  maximumScheduledDate(): Date {
    const maxDate = this.dateTime.local().plus({ days: 60 }).toJSDate();
    console.log(maxDate);
    return maxDate;
  }

  openTimeSheet(job: ISqlJob, event: any) {
    window.open(`https://portal.csina.com/field/billingInfo.aspx?src=SC&id=${job.scExternalJobId}`, '_blank');
  }

  async saveFinal(job: ISqlJob): Promise<void> {
    this.confirmationService.confirm({
      key: 'jobsManagerConfirmDialog',
      message: `Are you sure you want to submit job ${job.scJobId} for Q/C review?`,
      reject: () => {
        // do nothing
      },
      accept: async () => {
        const inspection = await this.jobsService.validateJobForQc(job.jobId, {});
        if (!inspection.isReadyForQc) {
          this.messageService.add({
            severity: 'error',
            summary: 'QC Submission Failed',
            detail: 'Please ensure all required fields are filled out correctly and you have uploaded your photos (if required for this inspection).'
          });
          return;
        }
        Loading.standard(`Submitting inspection for job ${job.scJobId} to the Q/C review team...`);
        this.jobsService.updateJob(job.jobId, {status: 'QC'})
          .then(res => {
            return this.jobsService.postFormDataLegacy(job.jobId);
          })
          .then(async (res) => {
            this.messageService.add({
              severity: 'success',
              summary: `Submitted job ${job.scJobId} to Q/C...`,
              detail: 'The form has been submitted successfully.'
            });
            this.notesService.createScJobNote(job.scJobId,
              `The inspector has completed the data entry and photo upload process. The status was updated to QC and is ready for review. `, true)
                .catch(e => console.error(`Unable to save note on Q/C submit and save.\n${e}`));
            await this.getJob(job.jobId);
            Loading.remove();
          })
          .catch (e => {
            console.log(`Unable to submit to Q/C.\n${e}`);
            Loading.remove();
            this.messageService.add({
              severity: 'error', summary: `Unable to submit job ${job.scJobId} }to Q/C...`,
              detail: 'The inspection was not submitted. Please try again or contact your coordinator for help.'
            });
          })
          .finally( () => {
            Loading.remove();
          });
      }
    });
  }

  async getJobs(): Promise<void> {
    try {
      Loading.standard('Loading Job List...');
      this.currentUser = await this.authService.userAuth();
      console.log(this.currentUser);
      this.currentUserRoleName = this.users.getRoleName(this.currentUser.roles[0]);
      this.currentUserPrimaryRole =this.currentUser.roles[0];
      this.jobs = await this.jobsService.getJobs(this.currentUser.userProfileId)
      if (this.jobs.length > 0) {
          this.statusTotals = this.getDistinctStatusesAndTotals(this.jobs);
          Loading.remove();
          // const activeJobRequests = await this.jobsService.getActiveJobRequests()
          // const prismaJobRequests = activeJobRequests;
      }
    } catch(error: any) {
      this.messageService.add({
        severity: 'error',
        summary: 'Unable to Load Job List',
        detail: error.message? error.message : 'An unknown error occurred.'
      });
    } finally {
        Loading.remove();
    }
  }
    async getJob(jobId: string): Promise<void> {
      return this.jobsService.getScJobByJobId(jobId)
        .then((updatedJob) => {
          // find job index in this.jobs then if the job index is valid update it
          const jobIndex = this.jobs.findIndex(j => j.jobId === updatedJob.jobId);
          if (jobIndex > -1) {
            this.jobs[jobIndex] = updatedJob;
            this.statusTotals = this.getDistinctStatusesAndTotals(this.jobs);
            this.jobs = [...this.jobs];
            this.cd.detectChanges();
          }
        })
        .catch(e => {
          console.log(e);
          this.messageService.add({
            severity: 'error',
            summary: 'Unable to Load Job',
            detail: e.message? e.message : 'An unknown error occurred.'
          });
        });
    }

    copyToClipboard(text: string): void {
      if ( this.clipboard.copy(text) ) {
        this.messageService.add({
          severity: 'success',
          summary: `${text}`,
          detail: `Rep email copied to clipboard`
        });
      }
    }

    formatEnumKey(key: string): string {
      try {
        const ACRONYMS = ['QC'];
        return key
          .split('_')
          .map(word => {
            if (ACRONYMS.includes(word)) {
              return word;
            } else {
              return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
            }
          })
          .join(' ');
      } catch (error) {
        console.error(`Unable to format enum key: ${key}.\nError: ${error}`);
        return 'Unknown';
      }
    }

    getJobStatusDropdownOptions(): any[] {
      return   Object.keys(JOB_STATUS)
      .filter(key => isNaN(Number(key)))
      .map(key => ({
        name: this.formatEnumKey(key),
        code: JOB_STATUS[key as keyof typeof JOB_STATUS]
      }));    
    }
    
    getJobStatusName(jobStatusId: JOB_STATUS): string {
      return this.formatEnumKey(Object.keys(JOB_STATUS).find(key => JOB_STATUS[key as keyof typeof JOB_STATUS] === jobStatusId)!);
    }

    showJobStatusPanel(jobStatusId: number, event: Event): void {
      this.selectedJobStatusId = jobStatusId;
    }

    async updateJobStatus(job: ISqlJob, overlaypanel: any): Promise<void> {
      if (!this.selectedJobStatusId || this.selectedJobStatusId === job.scJobStatusId) {
        this.messageService.add({
          severity: 'warn',
          summary: `The status for Job ${job.scJobId} was not changed`,
        });
        overlaypanel.toggle(false);
        return;
      }

      this.confirmationService.confirm({
        key: 'jobsManagerConfirmDialog',
        message: `Are you sure you want to update the status of Job ${job.scJobId} to "${this.getJobStatusName(this.selectedJobStatusId)}" status?`,
        accept: () => {
          const oldJobStatusId = job.scJobStatusId;
          const userName = `${this.currentUser?.FirstName || 'Unkown'} ${this.currentUser?.LastName || 'Unknown'}`;
          job.scJobStatusId = this.selectedJobStatusId;
          overlaypanel.toggle(false);         
          // Loading.standard(`Updating Job Status for ${job.scJobId}...`);
          this.jobsService.updateJobStatusId(job.scJobId, this.selectedJobStatusId)
            .then(res => {
              // Loading.remove();
              this.messageService.add({
                severity: 'success',
                summary: `Status Update Successful`,
                detail: `Job ${job.scJobId} has been updated to "${this.getJobStatusName(this.selectedJobStatusId)}" status.`
              });
              this.notesService.createScJobNote(job.scJobId,
                `Status changed from [ ${this.getJobStatusName(oldJobStatusId).toUpperCase()} ] to [ ${this.getJobStatusName(res.JobStatusId).toUpperCase()} ] by ${userName}.`, true)
                .catch(e => console.error(`Unable to save note for inspection status change.\n${e}`));
              this.getJob(job.jobId);
            })
            .catch(e => {
              console.log(`Unable to update job status to ${this.getJobStatusName(this.selectedJobStatusId)}.\n${e.message? e.message : 'An unknown error occurred.'});`);
              // Loading.remove();
              this.messageService.add({
                severity: 'error',
                summary: 'Status Update Failed',
                detail: `Unable to update job ${job.scJobId} to "${this.getJobStatusName(this.selectedJobStatusId)}" status.`,
              });
            })
            .finally(() => {
              this.selectedJobStatusId = 0;
            });
        },
        reject: () => {
          this.selectedJobStatusId = 0;
          overlaypanel.toggle(false);
        }
      });
    }

    async showDelivered() {
      try {
        if (this.isShowDelivered) {
          Loading.standard('Getting Active Jobs...');
          this.jobs = this.jobs.filter(job => job.scJobStatusId!== JOB_STATUS.DELIVERED);
          this.statusTotals = this.getDistinctStatusesAndTotals(this.jobs);
          this.isShowDelivered = false;
        } else {
          Loading.standard('Getting Delivered Jobs...');
          const deliveredJobs = await this.jobsService.getDeliveredJobs(90);
          if (deliveredJobs && deliveredJobs.length > 0) {
            this.isShowDelivered = true;
            this.jobs = [ ...this.jobs, ...deliveredJobs ];
            this.statusTotals = this.getDistinctStatusesAndTotals(this.jobs);
          }
        }
      } catch (error) {
        console.log(`Unable to get delivered jobs.\nError: ${error}`);
      } finally {
        Loading.remove();
      }
    }

    canShowInspectionFormButton(job: ISqlJob): boolean {
      if (this.currentUserPrimaryRole !== UserRole.REP && this.currentUserPrimaryRole !== UserRole.GUEST) {
        return true;
      } else if ((this.currentUserPrimaryRole === UserRole.REP || this.currentUserPrimaryRole === UserRole.GUEST) && (job.scJobStatusId !== JOB_STATUS.QC && job.scJobStatusId !== JOB_STATUS.ON_HOLD && job.scJobStatusId !== JOB_STATUS.INSURED)) {
        return true;
      } else {
        return false;
      }
    }

    openPdfFormDialog(event: any): void { 
      if (!event?.shiftKey) {
      this.ref = this.dialogService.open(PdfFormComponent, { 
        data: { url: ''},
        header: 'PDF Form',
        footer: ` `,
        width: '50vw',
        height: '75vh',
        closable: true,
        closeOnEscape: true,
        modal: true,
      });
  
      this.ref.onClose.subscribe((response) => {
  
      });
    }
  }
}
