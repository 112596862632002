import {Component, OnInit, ChangeDetectorRef, Input, ViewChild, Output, EventEmitter, output} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {MessageService} from 'primeng/api';
import loadImage from 'blueimp-load-image';
import {ISqlJob} from '../../jobs/sql-job.interface';
import {PhotoEditorComponent} from '../editor/photo-editor.component';
import {DialogService} from 'primeng/dynamicdialog';
import {ConfirmationService} from 'primeng/api';
import { nanoid } from 'nanoid';
import { PhotosService } from '../photos.service';
import {IFile} from '../interfaces/upload.models';
import padStart from 'lodash/padStart';
import {HttpEvent, HttpClient} from '@angular/common/http';
import { Job } from '../../jobs/job';
import heic2any from 'heic2any';
const orderBy = require('lodash/orderBy');
const sortBy = require('lodash/sortBy');
import { Loading, Block } from 'notiflix';
import { checkImage, HEIC, isHEIC } from 'check-image-type';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import {
  DndDraggableDirective,
  DndDropEvent,
  EffectAllowed,
} from 'ngx-drag-drop';
import { IPhoto } from '../interfaces/upload.models';
@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {
  files: any[] = [];
  photos: IPhoto[] = [];
  job: any;
  draggedFile: any;
  photoBucket: string = '';
  sortablejsOptions: any;
  photoLabels: any[] = [];
  saveDisabled = true;
  filesToSign: IFile[] = [];
  effectAllowed: EffectAllowed = 'all';
  isLoading = true;

  @Input() scJob: ISqlJob = {} as ISqlJob;

  @Output() hasUnsavedPhotoChanges: EventEmitter<boolean> = new EventEmitter<boolean>();

  hasPhotos = output<boolean>();

  constructor(
    private messageService: MessageService,
    private sanitizer: DomSanitizer,
    private cd: ChangeDetectorRef,
    private confirmationService: ConfirmationService,
    private photosService: PhotosService,
    private dialogService: DialogService,
    private http: HttpClient,
  ) {
  }

  onDrop(event: DndDropEvent) {
    const index = this.job.inspectionPhotos.findIndex((f: IPhoto) => f.photoId === event.data.photoId);
    if (event.index !== undefined && index !== -1) {
      moveItemInArray(this.job.inspectionPhotos, index, event.index);
      this.savePhotos()
      .then(() => {
        this.messageService.add({
          key: 'uploadToast',
          severity: 'success',
          summary: 'Update Successful',
          detail: 'Photo position has been updated',
        });
      });
    }
  }

  async ngOnInit(): Promise<void> {
    this.sortablejsOptions = {
      onStart: (event: any) => {
        this.cd.detach();
      },
      onEnd: async (evt: any) => {
        try {
          await this.savePhotos();
          this.messageService.add({
            key: 'uploadToast',
            severity: 'success', 
            summary: 'Photo Changes Saved',
          });
        } catch (error) {
          this.messageService.add({
            key: 'uploadToast',
            severity: 'error',
            summary: 'Error Saving Photos',
            detail: 'Failed to save photo changes'
          });
        }
      },
      scroll: true,
      scrollSensitivity: 100,
      filter: '.nodrag',
    };

    try {
      const job = await this.photosService.getJob(this.scJob.jobId);
      this.job = job;
      
      if (job.inspectionPhotos.length) {
        this.hasPhotos.emit(true);
        await this.loadPhotos(job.inspectionPhotos);
      } else {
        this.hasPhotos.emit(false);
      }

      const bucket = await this.photosService.getS3PhotoBucket();
      this.photoBucket = bucket;

      const photoLabels = await this.photosService.getPhotoLabels();
      this.photoLabels = sortBy(photoLabels, ['label']);
      this.photoLabels = this.photoLabels.map(l => {
        return {
          index: l.index,
          label: l.label,
          value: l.label
        };
      });

    } catch (error) {
      this.messageService.add({
        key: 'uploadToast',
        severity: 'error',
        summary: 'Error Loading Photos',
        detail: 'Failed to load photo data'
      });
    } finally {
      this.isLoading = false;
      this.cd.detectChanges();
    }
  }

  async loadPhotos(photos: IPhoto[]): Promise<void>{
    for (const [pIdx, p] of photos.entries()) {
      try {
        const photoBlob: Blob|void = await this.photosService.getS3Photo(p.url);
        if (photoBlob && photoBlob.size === p.size) {
          p.objectUrl = this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(photoBlob)));
          p.isValid = true;
        } else {
          p.isValid = false;
        }
      } catch (error) {
        console.error(`Error loading photo ${p.url}:`, error);
        p.isValid = false;
      }
    }
  }

  async savePhotos(): Promise<Job> {
    try {
      return await this.photosService.savePhotos(this.scJob.jobId, this.job.inspectionPhotos);
    } catch (error: any) {
      this.messageService.add({
        key: 'uploadToast',
        severity: 'error',
        summary: 'Error Saving Photos',
        detail: error.message || 'Failed to save photo changes'
      });
      throw error;
    }
  }

  uploadPhoto(file: File, url: string): Promise<File|void> {
    return new Promise((resolve, reject) => {
      try {
        this.photosService.uploadPhotoS3(file, url).subscribe({
          next: (event: HttpEvent<any>) => {
            try {
              switch (event.type) {
                case 1: // progress
                  break;
                case 4: // finished
                  if (event?.status === 200) {
                    this.messageService.add({
                      key: 'uploadToast',
                      severity: 'success',
                      summary: 'Photo Uploaded Successfully',
                      detail: `${file?.name || 'File'} was uploaded.`
                    });
                    resolve(file);
                  } else {
                    console.log(`Unable to upload ${file?.name || 'file'}`);
                    console.log(event);
                    this.messageService.add({
                      key: 'uploadToast',
                      severity: 'error',
                      summary: 'Photo Upload Failed',
                      detail: `${file?.name || 'File'} was not uploaded!`
                    });
                    reject(new Error('Unable to upload photo'));
                  }
                  break;
              }
            } catch (e: any) {
              console.error('Error processing upload response:', e);
              reject(e);
            }
          },
          error: (error) => {
            console.error('Upload subscription error:', error);
            reject(error);
          }
        });
      } catch (error) {
        console.error('Error initiating file upload:', error);
        reject(error);
      }
    });
  }

  async onFileSelect(event: any): Promise<void> {
    try {
      Loading.standard('Preparing upload...');

      let totalUploaded = 0;

      for (let [index, f] of event.currentFiles.entries()) {
        
        const buf = new Uint8Array(await f.slice(0, 64).arrayBuffer());
        const fileType = checkImage(buf);
        
        if (isHEIC(buf)) {
          Loading.change(`Converting HEIC to JPG (${index+1} of ${event.currentFiles.length})...`);
          const convertedImage = await heic2any({
            blob: f,
            toType: 'image/jpeg',
            quality: 1.0,
          });
          f = new File([convertedImage as Blob], f.name, { type: 'image/jpeg' });
        }
  
        Loading.change(`Resizing ${index+1} of ${event.currentFiles.length}...`);
  
        if (fileType?.mime.includes('image/')) {
          const image = await loadImage(f, { maxWidth: 1200, canvas: true }) as any;
          const canvas: HTMLCanvasElement = image.image
          const resizedBlob: Blob = await new Promise<Blob>((resolve, reject) => {
            canvas.toBlob((blob: Blob | null) => {
              if (blob) {
                resolve(blob);
              } else {
                reject(new Error('Failed to resize photo.'));
              }
            }, 'image/jpeg', 0.40);
          });

          Loading.change(`Uploading ${index+1} of ${event.currentFiles.length}...`);

          f = new File([resizedBlob], f.name, { type: 'image/jpeg'});
          const objectUrl = this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(f)));
          const fileId = `IMG_${nanoid()}`;
          const fileKey = `inspection-photos/${padStart(this.scJob.scJobId.toString(), 8, '0')}/${fileId}.jpg`;
          const signedUrls = await this.photosService.getSignedUrls([{
            id: fileId,
            key: fileKey,
            contentType: 'image/jpeg'
          }]);

          await this.uploadPhoto(f, signedUrls[0].signedUrl);

          this.job.inspectionPhotos.push({
            photoId: fileId,
            key: fileKey,
            url: signedUrls[0].signedUrl,
            size: f.size,
            type: 'image/jpeg',
            name: f.name,
            label: '',
            index: 0,
            isValid: false,
            bucket: this.photoBucket,
            objectUrl,
          } as IPhoto);

          totalUploaded++;
      }
        
      }
      Loading.change(`Validating photo${totalUploaded > 1 ? 's' : ''} and saving...`);
      await this.savePhotos();
      await this.photosService.validateUloadedPhotos(this.job.inspectionPhotos);
      this.messageService.add({
        key: 'uploadToast',
        severity: 'success',
        summary: 'Photo Upload Successful',
        detail: `${totalUploaded} of ${event.currentFiles.length} photos uploaded`
      });

      event.currentFiles = [];
      
    } catch (error: any) {
      Loading.remove();
      this.messageService.add({
        key: 'uploadToast',
        severity: 'error',
        summary: 'Photo Upload Failed',
        detail: `Something went wrong while uploading photos: ${error.message}`
      });
    } finally {
      Loading.remove();
    }
  }

  deletePhoto(photo: IPhoto, index: number): void {
    this.confirmationService.confirm({
      key: 'photoConfirmDialog',
      message: `Delete ${photo.name}${photo.label ? ' (' + photo.label + ')' : ''} ?`,
      accept: async () => {
        // remove the photo objectURL
        photo?.objectUrl ? window.URL.revokeObjectURL(photo.objectUrl as string) : null;
        const {bucket, key} = photo;
        this.job.inspectionPhotos.splice(index, 1);
        // remove photo
        if (photo.isValid) {
          const resp = await this.photosService.deleteS3Photo(bucket, key);
          console.log(resp);
        }
        this.savePhotos().then(() => {
          this.messageService.add({
            key: 'uploadToast',
            severity: 'success',
            summary: 'Photo Changes Saved',
            detail: `Photo ${photo.name} was removed.`
          });
        });
      }
    });
  }

  editPhoto(photo: IPhoto, i: number): void {
    if (photo.isValid) {
      const blobUrl = (photo.objectUrl as any).changingThisBreaksApplicationSecurity || photo.objectUrl;
      this.http.get(blobUrl, { responseType: 'blob' })
        .subscribe(blob => {
          const file = new File([blob], photo.name, { type: photo.type });
          const ref = this.dialogService.open(PhotoEditorComponent, {
            data: {
              file,
              photo
            },
            header: `Editing Photo [ "${file.name.toUpperCase()}" - ${photo.label ? photo.label : 'No Label'} ]`,
            height: '80vh',
            width: '80vw',
            showHeader: true,
            closeOnEscape: false,
            closable: false,
          });

          ref.onClose.subscribe(async (file: File) => {
            if (file) {
              this.job.inspectionPhotos[i].size = file.size;
              const signedUrls = await this.photosService.getSignedUrls([{
                id: photo.photoId,
                key: photo.key,
                contentType: 'image/jpeg'
              }]);
              photo.url = signedUrls[0].signedUrl;
              await this.uploadPhoto(file, signedUrls[0].signedUrl);
              photo.objectUrl = this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(file)));
              photo.isValid = true;
              this.savePhotos();
            }
          });
        });
    }
  }

  saveLabel(photo: IPhoto, e: any): void {
    const label = this.photoLabels.find(({ value }) => value === e.value);
    if (label) {
      photo.index = label.index;
    } else {
      photo.index = 0;
    }
    this.savePhotos()
      .then(() => {
        this.messageService.add({
          key: 'uploadToast',
          severity: 'success',
          summary: 'Photo Label Changes Saved',
          detail: photo.label
            ? `Photo ${photo.name} was labeled as "${photo.label}".`
            : `Photo ${photo.name} label removed.`
        });
      });
  }

  dragStart(photo: IPhoto): void {
    this.draggedFile = photo;
    console.log('drag starting...');
    console.log(photo);
  }

  dragEnd(): void {
    console.log('drag ending...');
  }

  drop(): void {
    this.draggedFile = null;
    console.log('item dropped...');
  }

  arrange(e: any, canSave: boolean): void {
    for (const [pIdx, p] of this.job.inspectionPhotos.entries()) {
      // sync label index
      if (p.label) {
        const label = this.photoLabels.find(l => l.label === p.label);
        if (label) {
          p.index = label.index;
        }
      } else {
        p.index = pIdx;
      }
    }
    this.job.inspectionPhotos = orderBy(this.job.inspectionPhotos, ['index'], ['asc']);
    if (canSave) {
      this.savePhotos()
        .then(() => {
          this.messageService.add({
            key: 'uploadToast',
            severity: 'success',
            summary: 'Photo Sort Order Saved',
          });
        });
    }
  }
}
